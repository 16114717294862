import { Clinic } from '@booking/types';

const GOOGLE_MAPS_BASE_URL = 'https://maps.google.com/';

export const clinicToGoogleMapsLink = (clinic?: Clinic) => {
  if (!clinic) return GOOGLE_MAPS_BASE_URL;
  const address =
    clinic?.address &&
    clinic?.address.street &&
    clinic?.address.zip &&
    clinic?.address.city
      ? `, ${clinic?.address.street}, ${clinic?.address.zip} ${clinic?.address.city}`
      : '';
  const clinicName = `Dr. Dropin ${clinic?.name}${address}`;
  const queryString = encodeURIComponent(clinicName);
  return `${GOOGLE_MAPS_BASE_URL}?q=${queryString}`;
};
