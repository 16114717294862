import {
  getFormattedAppointmentDateTime,
  getFormattedBirthDate,
  getFormattedPhoneNumber,
  useClinicStore,
  useNavigationStore,
  usePersonalDataStore,
  useServiceTreeStore,
} from '@booking/shared';
import { Actions, Locales } from '@booking/types';
import useAnalytics from '@hooks/useAnalytics';
import { clinicToGoogleMapsLink } from '@utils/mapQuery';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

type ReceiptProps = {
  className?: string;
};

export default function Receipt({ className }: ReceiptProps) {
  const { t } = useTranslation('booking');
  const queries = useNavigationStore((state) => state.queries);
  const personalDetails = usePersonalDataStore()(
    (state) => state.personalDetails,
  );
  const clinic = useClinicStore()((state) => state.clinic);
  const locale = useRouter().locale as Locales;
  const getServiceName = useServiceTreeStore((state) => state.getServiceName);
  const getServiceByShortId = useServiceTreeStore(
    (state) => state.getServiceByShortId,
  );
  const service = getServiceByShortId(queries.service);

  const serviceName = getServiceName(queries.service, locale);
  const setEventProps = useAnalytics();

  const [formattedDateTime, setFormattedDateTime] = useState<string>('');
  const [formattedBirthdate, setFormattedBirthdate] = useState<string>('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<string>('');

  const getSpecialityName = useServiceTreeStore(
    (state) => state.getSpecialityName,
  );
  const specialityName = getSpecialityName(
    queries.vertical,
    queries.speciality,
    locale,
  );

  useEffect(() => {
    if (
      personalDetails.birthdate &&
      personalDetails.date &&
      personalDetails.time &&
      personalDetails.phone
    ) {
      setFormattedDateTime(
        getFormattedAppointmentDateTime(
          personalDetails.date,
          personalDetails.time,
          locale,
        ),
      );

      setFormattedBirthdate(getFormattedBirthDate(personalDetails.birthdate));
      setFormattedPhoneNumber(
        getFormattedPhoneNumber(personalDetails.phone) || '',
      );
    }
  }, [personalDetails.date, personalDetails.time]);

  return (
    <div
      className={classNames(
        'prose prose-p:leading-6 prose-p:m-0 prose-headings:m-0 ph-no-capture  rounded-lg bg-white text-black',
        className,
      )}
    >
      <div className="relative flex flex-col gap-2 p-6">
        <div className="border-warning rounded-lg border bg-[#FCF0E1] p-4">
          <p className="text-center text-black [&>strong]:font-medium">
            <Trans
              t={t}
              i18nKey={'steps.confirm.info_in_future'}
              ns={'booking'}
              components={{ strong: <strong /> }}
              values={{
                cancellation_limit: service?.freeCancellationLimitInHours ?? 24,
              }}
            />
          </p>
        </div>
      </div>
      <PunchInDivider />

      <p className="pb-8 pt-4 text-center text-lg leading-7">
        {t('receipt.summary')}
      </p>
      <div className="flex flex-col gap-2">
        <InfoRow label={t('steps.confirm.name')} value={personalDetails.name} />
        <InfoRow
          label={t('steps.confirm.patient_birthdate')}
          value={formattedBirthdate}
        />
        <InfoRow
          label={t('steps.confirm.ordered_by')}
          value={formattedPhoneNumber}
        />

        <PunchInDivider />

        <InfoRow label={t('steps.confirm.speciality')} value={specialityName} />
        <InfoRow label={t('steps.confirm.service')} value={serviceName} />

        <InfoRow
          label={t('steps.confirm.practitioner')}
          value={personalDetails.practitionerName}
        />
        {formattedDateTime ? (
          <InfoRow
            label={t('steps.confirm.selected_date_and_time')}
            value={formattedDateTime}
          />
        ) : null}
        <PunchInDivider />
        <div className="flex flex-col justify-between px-8">
          <div className="flex flex-col">
            <p className="font-medium">{clinic?.name}</p>
            <p>
              {clinic?.address.street}, {clinic?.address.zip}{' '}
              {clinic?.address.city}
            </p>
          </div>
          <div className="mb-2 cursor-pointer py-4 no-underline">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={clinicToGoogleMapsLink(clinic)}
              onClick={() => {
                setEventProps({
                  event: {
                    action: Actions.OpenMap,
                  },
                });
              }}
              className="no-underline"
            >
              <span className="border-primary text-primary  rounded-full border bg-white px-4 py-2 text-base leading-4 no-underline">
                {t('map.button_text', { ns: 'common' })}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export const PunchInDivider = () => (
  <div className="relative my-4 w-full">
    <div className="mx-auto w-10/12 border-[1px] border-dashed border-black/30" />
    <div className="bg-primary absolute -bottom-[6px] -left-2 h-4 w-4 rounded-full" />
    <div className="bg-primary absolute -bottom-[6px] -right-2 h-4 w-4 rounded-full" />
  </div>
);

const InfoRow = ({ label, value }: { label: string; value?: string }) => {
  if (!value) return null;

  return (
    <div className="flex flex-col justify-between px-8 sm:flex-row">
      <p className="font-medium">{label}</p>
      <p className="text-right">{value}</p>
    </div>
  );
};
